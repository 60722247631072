<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'respiration-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :tooltip="IsDashboard ? {} : tooltip"
      background="transparent"
      width="100%"
      :title="
        IsDashboard
          ? $t('module.charts.' + Chart.Name) + ' [' + $t(Chart.DisplayUnit) + ']'
          : ''
      "
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <!-- MIN INHALATIONS -->
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData.MinInhalationsPerMinute.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="IsDashboard ? {} : marker"
          :name="$t('module.diaries.respiration.minimum')"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData.MinInhalationsPerMinute.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="marker"
          :name="$t('module.diaries.respiration.minimum')"
        ></e-series>
        <!-- MAX INHALATIONS -->
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData.MaxInhalationsPerMinute.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="IsDashboard ? {} : marker"
          :name="$t('module.diaries.respiration.maximum')"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData.MaxInhalationsPerMinute.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="marker"
          :name="$t('module.diaries.respiration.maximum')"
        ></e-series>
        <!-- SUM INHALATIONS -->
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData.TotalInhalationsCount.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="IsDashboard ? {} : marker"
          :name="$t('module.diaries.respiration.total')"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData.TotalInhalationsCount.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="marker"
          :name="$t('module.diaries.respiration.total')"
        ></e-series>
        <!-- AVG INHALATIONS -->
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData.AvgInhalationsCount.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="IsDashboard ? {} : marker"
          :name="$t('module.diaries.respiration.average')"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData.AvgInhalationsCount.chartData"
          type="Line"
          xName="x"
          yName="y"
          :marker="marker"
          :name="$t('module.diaries.respiration.average')"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
    <!-- <b-button>Összes exportálás</b-button> -->
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "../../../../../Logic/Backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  ColumnSeries,
  DateTime,
  Tooltip,
  StripLine,
  LineSeries,
  Legend /* chart components */,
} from "@syncfusion/ej2-vue-charts";
import { HealthPlanLogic } from "../../../../../Logic/Backend/health-plan.js";
import { ChartUiLogic } from "@/Logic/Ui/chart-ui";
import { ChartBackendLogic } from "../../../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  components: { IntervalManager },
  name: "PulmonologyRespiration",
  data() {
    return {
      chartData: {
        MinInhalationsPerMinute: {
          chartData: [],
        },
        MaxInhalationsPerMinute: {
          chartData: [],
        },
        TotalInhalationsCount: {
          chartData: [],
        },
        AvgInhalationsCount: {
          chartData: [],
        },
      },
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard ? null : this.$t("module.charts.Respiration"),
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      /* palettes: ["var(--fp-dark-orange)","var(--fp-dark-gray)"], */
      palettes: ["#137d2f", "#c91016", "#0d1ca1", "#252926"], // CONSIDERm: hexa helyett valami fp?
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        header:
          this.$t("module.charts." + this.Chart.Name) +
          " [" +
          this.$t(this.Chart.DisplayUnit) +
          "]",
        format: "${point.x} : <b>${point.y}</b> " + this.$t(this.Chart.DisplayUnit),
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--fp-dark-orange)",
      },
      changeDataList: this.changeData,
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
    changeData: Boolean,
  },
  watch:{
    async changeData(input){
      this.changeDataList = input
      if(input){
        await this.getData()
      }
    },
    changeDataList(input){
      this.$emit('update:changeData', input)
    }
  },
  provide: {
    chart: [
      ColumnSeries,
      DateTime,
      Tooltip,
      StripLine,
      LineSeries,
      Legend,
    ] /* chart components */,
  },
  methods: {
    //intervallum beállítása
    setInterval() {
      //intervall objektum generálás dátum és intervallum alapján
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      //intervallum objektum tárolása
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
    },
    //intevallum csere
    async intervalChanged(currentDate, interval) {
      //kiválasztott dátum tárolás
      this.currentDate = currentDate;
      //kiválasztott intervallum tárolás
      this.interval = interval;
      //intervallum napi?
      if (interval == this.$enums.ChartInterval.Day.value) {
        //chart típus napló tárolás
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        //kapott chart típus tárolás
        this.chartType = this.ChartType;
      }
      //adatok betöltése
      await this.getData();
      //intervallum beállítása
      this.setInterval();
    },
    //adatok betöltése
    async getData() {
      //chart típus chart?
      if (this.chartType == this.$enums.DiagramType.chart) {
        //igen, chart adatok kérés
        await this.getChartData();
        //chart típus napló?
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        //igen napló, napló adatok kérés
        await this.getDiaryData();
      }
      //terv adatok betöltése
      await this.getPlan();
      this.changeDataList = false
    },
    //terv adatok kérés
    async getPlan() {
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg terv adat kérés
        await this.getPatientPlanData();
      } else {
        //nincs, saját terv adat kérés
        await this.getPlanData();
      }
    },
    //beteg terv adat kérés
    async getPatientPlanData() {
      //console.log("Patient ExerciseDistance Plan");
    },
    //saját terv adat kérés
    async getPlanData() {
      //kérés indítás
      let getPlanDataResponse = await HealthPlanLogic.getHealthPlanList(
        this.Chart.DisplayPlan.planType,
        {
          Status: this.$enums.HealthGoalStatus.InProgress.Value,
          Type: this.Chart.DisplayPlan.planModuleType,
        }
      );
      //sikeres kérés?
      if (!getPlanDataResponse.Code) {
        //kapott tev lista nem nulla elemű?
        if (getPlanDataResponse.length > 0) {
          //nem, lista első elmének terv érték tárolás
          var planValue = getPlanDataResponse[0].TargetValue;
          //terv megjelenő tároló megadása
          Vue.set(this.yAxis, "stripLines", [
            {
              start: planValue,
              end: 10000000000000000,
              isSegmented: true,
              visible: true,
              zIndex: "Behind",
              opacity: 0.25,
              color: "green",
            },
          ]);
        }
      } else {
        this.$bvToast.toast(getPlanDataResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart adat kérés
    async getChartData() {
      //kérés válasz
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg chart adat kérés
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nem, saját chart adat kérés
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott chart adat tárolás
        this.chartData = responseChartData;
        //üresmező feltöltés
        this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
          [this.Chart.DataProperties.chart.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //napló adat kérés
    async getDiaryData() {
      //kérés válasz
      var responseChartData = null;
      //van betegid?
      if (this.PatientId) {
        //igen, beteg napló adat kérés
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        //nem, saját beteg napló adat kérés
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott napló adat tárolás
        // this.chartData = responseChartData;
        this.convertChartData(responseChartData);
        //üres mező feltöltés
        this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
          [this.Chart.DataProperties.diary.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    convertChartData(data) {
      this.chartData.MinInhalationsPerMinute.chartData = [];
      this.chartData.MaxInhalationsPerMinute.chartData = [];
      this.chartData.TotalInhalationsCount.chartData = [];
      this.chartData.AvgInhalationsCount.chartData = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        this.chartData.MinInhalationsPerMinute.chartData[i] = {
          x: element.MeasuredAt,
          y: element.MinInhalationsPerMinute,
        };
        this.chartData.MaxInhalationsPerMinute.chartData[i] = {
          x: element.MeasuredAt,
          y: element.MaxInhalationsPerMinute,
        };
        this.chartData.TotalInhalationsCount.chartData[i] = {
          x: element.MeasuredAt,
          y: element.TotalInhalationsCount,
        };
        this.chartData.AvgInhalationsCount.chartData[i] = {
          x: element.MeasuredAt,
          y: this.calculateAverage(
            element.MeasurementDuration,
            element.TotalInhalationsCount
          ),
        };
      }
    },
    // átlag légzés számítás
    calculateAverage(length, count) {
      const mins = moment.duration(length).asMinutes();
      return Math.round(count / mins);
    },
    //nap intervallum paraméter megadás
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    //chart intervallum paraméter megadás
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //üres mezők feltöltése
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kapott chart típus tárolsá
    this.chartType = this.ChartType;
    //kapott intervallum tárolása
    this.interval = this.Interval;
    //intervallum beállítása
    this.setInterval();
    //adatok betöltése
    // test data
    await this.getData();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--fp-dark-orange);
  font-size: 23px;
}
</style>
